import { useEffect, useState } from 'react'
import { Routes, Route, BrowserRouter, useLocation } from 'react-router-dom'
import { TagManager } from './TagManager'
import { getLineId } from './util/lineId'

const rootStyle: React.CSSProperties = {
  paddingTop: '128px',
}

const buttonStyle: React.CSSProperties = {
  border: '0px solid black',
  boxSizing: 'border-box',
  display: 'flex',
  flexBasis: 'auto',
  flexShrink: 0,
  margin: 0,
  minHeight: 0,
  minWidth: 0,
  padding: 0,
  position: 'relative',
  zIndex: 0,
  opacity: 1,
  height: '50px',
  backgroundColor: 'rgb(0, 0, 0)',
  flexDirection: 'row',
  touchAction: 'manipulation',
  cursor: 'pointer',
  userSelect: 'none',
  transitionProperty: 'opacity',
  transitionDuration: '0.15s',
  justifyContent: 'center',
  alignItems: 'center',
}

const buttonLabelStyle: React.CSSProperties = {
  border: '0px solid black',
  boxSizing: 'border-box',
  display: 'inline',
  font: '14px system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Ubuntu, "Helvetica Neue", sans-serif',
  margin: 0,
  padding: 0,
  whiteSpace: 'pre-wrap',
  overflowWrap: 'break-word',
  unicodeBidi: 'isolate',
  color: 'rgb(255, 255, 255)',
  fontWeight: 'bold',
  fontSize: '20px',
}

const accessLabelStyle: React.CSSProperties = {
  height: '50px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  font: '14px system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Ubuntu, "Helvetica Neue", sans-serif',
  fontSize: '20px',
}

export const Redirect = () => {
  const search = useLocation().search
  const query = new URLSearchParams(search)
  const utmSource = query.get('utm_source') ?? ''
  const destination = query.get('destination') ?? ''
  const [isTimer, setIsTimer] = useState(false)

  useEffect(() => {
    TagManager.initialize()
    if (destination != null && destination.length > 0) {
      setTimeout(() => {
        setTimeout(() => {
          setIsTimer(true)
        }, 1000)
        window.location.href = destination
      }, 1000)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  
  const onClickBackButton = () => {
    window.location.href = `https://line.me/R/ti/p/${getLineId(utmSource)}`
  }

  return (
    <div style={rootStyle}>
      {isTimer === true ? (
        <div style={buttonStyle} onClick={onClickBackButton}>
          <div style={buttonLabelStyle}>トークに戻る</div>
        </div>
      ) : (
        <div style={accessLabelStyle}>
          <div>アクセス中...</div>
        </div>
      )}
    </div>
  )
}

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Redirect />} />
      </Routes>
    </BrowserRouter>
  )
}

export default App
