import {config} from '../environment/config'

interface LineIdInterface {
  [key: string]: {
    [key: string]: string
  }
}

const LINE_IDS: LineIdInterface = {
  //TokyuStore
  tokyustore: {
    'itg': '@931qgrwz',
    'itg-preview': '',
    'test': '@778gxebu',
    'test-preview': '',
    'test2': '@620debxu',
    'test2-preview': '',
    'stg': '@829cbcrl',
    'stg-preview': '@702hngxy',
    'stg2': '@829rddni',
    'stg2-preview': '@400gymgt',
    'prd': '@167xjzgp',
    'prd-preview': '@269rmmld',
  },
  //TokyuDept
  tokyudept: {
    'itg': '@195rcfqy',
    'itg-preview': '',
    'test': '@202jzksu',
    'test-preview': '',
    'test2': '',
    'test2-preview': '',
    'stg': '@817dslwq',
    'stg-preview': '@667zumri',
    'stg2': '@437sxhnu',
    'stg2-preview': '@629dbebu',
    'prd': '@914tatig',
    'prd-preview': '@149kaiaf',
  },
  //Foodshow
  foodshow: {
    'itg': '@363vhrjy',
    'itg-preview': '',
    'test': '@725wvbxq',
    'test-preview': '',
    'test2': '',
    'test2-preview': '',
    'stg': '@699qmbzy',
    'stg-preview': '@545xhxoi',
    'stg2': '@674bgtsc',
    'stg2-preview': '@727msmtc',
    'prd': '@863vovat',
    'prd-preview': '@160qprbi',
  },
  //TokyuBeauty
  tokyubeauty: {
    'itg': '@024xsuon',
    'itg-preview': '',
    'test': '@332jrith',
    'test-preview': '',
    'test2': '',
    'test2-preview': '',
    'stg': '@598ibtqr',
    'stg-preview': '@160plcin',
    'stg2': '@656iznkc',
    'stg2-preview': '@803kgozv',
    'prd': '@114jbisj',
    'prd-preview': '@216odrrz',
  },
  //ShinQs
  shinqs: {
    'itg': '@063gmjaq',
    'itg-preview': '',
    'test': '@712bzihe',
    'test-preview': '',
    'test2': '',
    'test2-preview': '',
    'stg': '@046dewub',
    'stg-preview': '@394svrot',
    'stg2': '@197axsnl',
    'stg2-preview': '@612cgebc',
    'prd': '@974cjjuu',
    'prd-preview': '@295wjicw',
  },
  //Etomo
  etomo: {
    'itg': '@803yfmjt',
    'itg-preview': '',
    'test': '@663kfsae',
    'test-preview': '',
    'test2': '@667rqdbp',
    'test2-preview': '',
    'stg': '@699dqnpl',
    'stg-preview': '@154vyvor',
    'stg2': '@429yiiib',
    'stg2-preview': '@609qaszp',
    'prd': '@153nttij',
    'prd-preview': '@674rzcdm',
  },
  //TPS
  tps: {
    'itg': '@560bnytf',
    'itg-preview': '',
    'test': '',
    'test-preview': '',
    'test2': '',
    'test2-preview': '',
    'stg': '@395rptrd',
    'stg-preview': '@137zemwy',
    'stg2': '',
    'stg2-preview': '',
    'prd': '@886lahkg',
    'prd-preview': '',
  },
  //Futako
  futako: {
    'itg': '@149zcbfk',
    'itg-preview': '',
    'test': '@214ncain',
    'test-preview': '',
    'test2': '',
    'test2-preview': '',
    'stg': '@699dqnpl',
    'stg-preview': '@445haxas',
    'stg2': '',
    'stg2-preview': '',
    'prd': '@upg1592u',
    'prd-preview': '@124mlfuv',
  },
  //TamaPlus
  tamaplaza: {
    'itg': '@442zmgib',
    'itg-preview': '',
    'test': '@713klmcr',
    'test-preview': '',
    'test2': '',
    'test2-preview': '',
    'stg': '@959vrstz',
    'stg-preview': '@442brqtn',
    'stg2': '',
    'stg2-preview': '',
    'prd': '@476amtef',
    'prd-preview': '@572qtqos',
  },
}

export const getLineId = (tenantName: string) => {
  return LINE_IDS[tenantName][config.SYSTEM_ENV]
}
