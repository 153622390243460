import { config } from './environment/config'

export const TagManager = {
  initialize: () => {
    const gtmId = config.GTM_ID
    const iframSnippet = `<iframe src="https://www.googletagmanager.com/ns.html?id=${gtmId}"
      height="0" width="0" style="display:none;visibility:hidden"></iframe>`
    const noScript = () => {
      const noscript = document.createElement('noscript')
      noscript.innerHTML = iframSnippet
      return noscript
    }

    const scriptSnippet = `(function(w,d,s,l,i){w[l]=w[l]||[];
      w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});
      var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';
      j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;
      f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','${gtmId}');`

    const script = () => {
      const scriptEl = document.createElement('script')
      scriptEl.innerHTML = scriptSnippet
      return scriptEl
    }

    document.head.insertBefore(script(), document.head.childNodes[0])
    document.body.insertBefore(noScript(), document.body.childNodes[0])
  },
}
